import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "アナログな業界をテクノロジーで変える、X-Tech な CTO 大集合！ - デブサミ 2018 に登壇して来ました -",
  "date": "2018-03-08T06:54:32.000Z",
  "slug": "entry/2018/03/08/155432",
  "tags": ["medley"],
  "hero": "./2018_03_08.png",
  "heroAlt": "cto"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、メドレー広報の阿部です。先日開催された Developers Summit（デブサミ）2018 に、メドレーの CTO ・平山が登壇しました。`}</p>
    <p>{`デブサミの今回のテーマは「`}<strong parentName="p">{`変わるもの × 変わらないもの`}</strong>{`」。`}</p>
    <p>{`レガシーな業界がインターネットの力で変わりつつある、その面白さをエンジニアに知ってもらえたらいいですね、と`}<a parentName="p" {...{
        "href": "https://codezine.jp/"
      }}>{`CodeZine`}</a>{`／`}<a parentName="p" {...{
        "href": "https://edtechzine.jp/"
      }}>{`EdTechZine`}</a>{`編集長の斉木さんと盛り上がったことで、トークセッションが実現。`}</p>
    <p><strong parentName="p">{`「医療 ×IT」としてメドレー CTO ・平山が、「金融 ×IT」としてマネーフォワード CTO ・中出さんが、「飲食 ×IT」としてトレタ CTO ・増井さん`}</strong>{`が登壇。ファシリテーターに`}<a parentName="p" {...{
        "href": "https://edtechzine.jp/"
      }}>{` `}</a>{`CodeZine／EdTechZine 編集長の斉木さんをお迎えしての実施となりました。`}</p>
    <p>{`どんな話が飛び出したのか、一部ではありますが紹介します！`}</p>
    <p><a parentName="p" {...{
        "href": "https://event.shoeisha.jp/static/images/event/741/1200.png"
      }}><img parentName="a" {...{
          "src": "https://event.shoeisha.jp/static/images/event/741/1200.png",
          "alt": "https://event.shoeisha.jp/static/images/event/741/1200.png"
        }}></img></a></p>
    <h1>{`そもそも X-Tech って？`}</h1>
    <p>{` `}{`斉木さんは冒頭で、X-Tech について「`}<strong parentName="p">{`IT の導入が遅れている業界において、 スタートアップが洗練された IT 技術により新たな価値や仕組みを提供する動き`}</strong>{`」と定義。実際に各事業ではどういう感じか？と話が進みました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180228/20180228141346.jpg",
        "alt": "f:id:medley_inc:20180228141346j:plain",
        "title": "f:id:medley_inc:20180228141346j:plain"
      }}></img></p>
    <p>{`Fintech の将来像として「`}<strong parentName="p">{`キャッシュレス社会を実現したい`}</strong>{`。お店にとって、現金って本当は不便なもののはず。毎日お釣り金を準備しないといけないし、あまり大金をお店に置いておけないから、定期的に銀行に入金しに行ったりしているのが現状。そういう煩わしさを、少しずつ無くしていきたいですね」と、マネーフォワード・中出さん。`}</p>
    <p>{`お金＝現金というような”当たり前”が深く根ざしているのは、医療の世界も同様です。`}</p>
    <p>{`「医療は、未だに紙のカルテや FAX 文化が残っていたり、そもそもインターネットが浸透していない。もちろん医療システムもありますが、医師や医療従事者の言うことをそのまま聞いて作ったというものも多くて、`}<strong parentName="p">{`全体最適が取れていない`}</strong>{`という課題もあります」と弊社・平山も話します。`}</p>
    <h1>{`多くの店舗に共通する課題を本質的に解決していくことが大切`}</h1>
    <p>{`X-Tech では、インターネットサービスにより大きな変化がおこる分、これまで使い慣れていないサービスだけに、様々な改善要望が入ることも少なくありません。`}</p>
    <p>{`「実際にサービスを使って頂いている飲食店から様々な要望を頂くのですが、弊社は一切カスタマイズをしない、というスタンスをとっています。飲食店の価格やタイプは様々だけど、突き詰めると実は課題は共通していたりする。ヒアリングしながら、`}<strong parentName="p">{`本質的な課題を見つけて、解決策を提供する`}</strong>{`ようにしています」と、予約/顧客管理サービス「`}<a parentName="p" {...{
        "href": "https://toreta.in/jp/"
      }}>{`トレタ`}</a>{`」を提供するトレタ・増井さん。`}</p>
    <p>{`これは医療でも同様で「レガシーな業界だと、医師のいう通りにプロダクトを作ることに従うなどの関係性も生まれやすいという状況はあります。でもそこは、`}<strong parentName="p">{`専門分野が違う対等な存在と捉えて、プライドを持って議論をできることが大切`}</strong>{`ですよね」と平山も答えます。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180228/20180228141409.jpg",
        "alt": "f:id:medley_inc:20180228141409j:plain",
        "title": "f:id:medley_inc:20180228141409j:plain"
      }}></img></p>
    <h1>{`X-Tech で活躍できるエンジニアは？`}</h1>
    <p>{`CTO 対談ということもあり、話は「X-Tech を支える組織作り」に。`}</p>
    <p>{`会社で働くエンジニアの特徴や求められるものを聞かれると、`}</p>
    <p>{`「飲食経験者が多いわけではないのですが、食べるのが好きな人は多い。社員同士で食事に行くことも多く、たまにエンゲル計数大丈夫かなと思います（笑）。スタートアップだからこそ、求められる役割が固定されず日々変わっています。有機的に変わることに抵抗感がないことが大切ですね」と、増井さん。`}</p>
    <p>{`中出さんも「たしかに、世の中の課題解決へのモチベーションが強いと思う」とこれに同意。さらに平山も「`}<strong parentName="p">{`プロダクトに誇りを持っている人が多い`}</strong>{`ですよね」と続けました。`}</p>
    <h1>{`X-Tech ならではの開発チームって？`}</h1>
    <p>{`最後に、組織づくりや採用で気をつけていることには、各社こんな回答がありました。`}</p>
    <p>{`「マネーフォワードでは、プロダクトごとにチームを組んでいて、`}<strong parentName="p">{`スモールチームで運営することを心がけています`}</strong>{`。技術選定も含めて、そのチームが使うべきと判断したらいいと。共有化も大切ですが、それが足かせになることもある。私が CTO になってから、そういうものは最低限に整理しました。」 （中出さん）`}</p>
    <p>{`「採用はずっと頑張っているけど、ずっと足りていません。もともと経験的にシニア〜ミドル層を採用したいと考えていましたが、現在はジュニアまで幅を広げています。ただ、スタートアップでは残念ながらゼロからプログラムを教える余裕はないことが多い。だからこそ”`}<strong parentName="p">{`僕らが何を与えられるのか”をすごく考えています`}</strong>{`。技術やビジネスマナーのイロハは十分に教えられないけれど、業界や技術の面白さはトレタだからこそ与えられることがあると思う。」（増井さん）`}</p>
    <p>{`「今メドレーはエンジニアとデザイナー、ディレクター、医師で 30 人くらいの開発チームですが、`}<strong parentName="p">{`職種間の隙間をなくすことを徹底しています`}</strong>{`。iOS エンジニア、サーバサイド、フロントエンド、と担当を分けることで情報断絶が起きる。ミッションによって人をアサインし、職種を横断して取り組める環境を作っています」（平山）`}</p>
    <p>{`最後に平山は「X-Tech は Web エンジニアのものと思われがちですが、toB 向けに広く展開しているプロダクトが多く、（デブサミの参加者に多いような）SIer 系のエンジニアの方にも近しい匂いを感じてもらえる世界だと思う。ぜひ次のキャリアとして、インターネットの会社も選択肢にあるんだというのを思っていただけると嬉しいです！」と力強くコメントして、セッションを締めました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180222/20180222161519.jpg",
        "alt": "f:id:medley_inc:20180222161519j:plain",
        "title": "f:id:medley_inc:20180222161519j:plain"
      }}></img></p>
    <p>{`2 月には「`}<a parentName="p" {...{
        "href": "https://tech.nikkeibp.co.jp/"
      }}>{`日経 xTECH`}</a>{`」が創刊されるなど、ますます注目の X-Tech 分野。`}</p>
    <p>{`どんなことができる業界なのか、メドレーはどんなビジョンに向けて動いているのかなど、もっと話を聞いてみたいという方は、ぜひご連絡ください！`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="株式会社メドレーの最新情報 - Wantedly" src="https://hatenablog-parts.com/embed?url=https%3A%2F%2Fwww.wantedly.com%2Fcompanies%2Fmedley" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.wantedly.com/companies/medley">www.wantedly.com</a></cite>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      